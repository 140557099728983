// extracted by mini-css-extract-plugin
export var appear = "employeeProfile-module--appear--pUwQ8";
export var body = "employeeProfile-module--body--Zt4pg";
export var caption = "employeeProfile-module--caption--EU4Mh";
export var container = "employeeProfile-module--container--Xf26j";
export var details = "employeeProfile-module--details--Eua1P";
export var floating = "employeeProfile-module--floating--JkH16";
export var image = "employeeProfile-module--image--24n-D";
export var name = "employeeProfile-module--name--AS2Rb";
export var position = "employeeProfile-module--position--86M2x";
export var rounded = "employeeProfile-module--rounded--X+1YJ";
export var roundedCorners = "employeeProfile-module--rounded-corners--WGwQO";
export var subtitle = "employeeProfile-module--subtitle---N0ma";
export var textBlack = "employeeProfile-module--text-black--NnmOV";
export var textBlue = "employeeProfile-module--text-blue--FndJQ";
export var textRed = "employeeProfile-module--text-red--KlpGL";
export var textWhite = "employeeProfile-module--text-white---rd4e";
export var title = "employeeProfile-module--title--zDVsg";