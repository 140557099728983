import { buildCdnImageUrl } from 'services/url';

type UseAboutPageReturn = {
  employees: {
    imageUrl: string;
    name: string;
    position: string;
  }[];
};

export function useAboutPage(): UseAboutPageReturn {
  return {
    employees: [
      {
        name: 'Dominic Laudate',
        position: 'Founder, CEO',
        imageUrl: buildCdnImageUrl('/team/dominic-laudate-2021.jpg'),
      },
      {
        name: 'Ian Donovan',
        position: 'CCO, Software Engineer',
        imageUrl: buildCdnImageUrl('/team/ian-donovan.jpg'),
      },
      {
        name: 'Jordan Carter',
        position: 'Software Engineer',
        imageUrl: buildCdnImageUrl('/team/jordan-carter-2022.jpg'),
      },
    ],
  };
}
