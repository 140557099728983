import { EmployeeProfile } from 'components/employeeProfile/employeeProfile';
import { StandardLayout } from 'layouts/standardLayout/standardLayout';
import { useAboutPage } from 'pageHooks/aboutHooks';
import * as pageClasses from 'pages/about.module.scss';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const AboutPage: React.FC = () => {
  const { employees } = useAboutPage();

  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>ScheduleLab - About</title>
        <meta charSet="utf-8" />
      </Helmet>

      <StandardLayout title="About Us">
        <article className={pageClasses.contactUsSection}>
          <p className={pageClasses.contactUsText}>Have a question? Found a bug?</p>
          <a className="button-outline-white" href="mailto:support@schedulelab.io">
            Contact Us
          </a>
        </article>
        <article className={pageClasses.aboutUsSection}>
          <section>
            <h2 className={pageClasses.title}>Humble Beginnings</h2>
            <p className={pageClasses.paragraph}>
              ScheduleLab originally started in 2016 as a team of two college friends: Dominic Laudate & Jake
              Harrington.
            </p>
            <p className={pageClasses.paragraph}>
              Back then the team was called Kwidil. The two of them created College Schedule Builder, an app built upon
              a simple scheduling tool. As college students themselves, they understood the pains of registration and
              sought to fix it, and took it further thanks to the Wentworth Institute of Technology’s entrepreneurial
              program. The project grew over the years from a simple Android project to a multiplatform product, and in
              2019 Dom, Ian, & Jordan founded the company you see today.
            </p>
            <p className={pageClasses.paragraph}>
              Following a monumental rebrand in early 2020, we changed our name to ScheduleLab, LLC. and relaunched our
              product line as ScheduleLab. While our name is different, we’re still committed providing the same quality
              service as we have for years, and we’ve got so much more in store!
            </p>
          </section>
          <hr className={pageClasses.separator} />
          <section>
            <h2 className={pageClasses.title}>Meet the Team</h2>
            <p className={pageClasses.paragraph}>
              We’re a small team right now, but that just means it’s easier to get to know us!
            </p>
            <section className={pageClasses.employeeProfiles}>
              {employees.map((employee) => (
                <EmployeeProfile key={employee.name} {...employee} />
              ))}
            </section>
          </section>
        </article>
      </StandardLayout>
    </main>
  );
};

export default AboutPage;
