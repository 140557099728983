import * as componentClasses from 'components/employeeProfile/employeeProfile.module.scss';
import React from 'react';

type EmployeeProfileProps = {
  imageUrl: string;
  name: string;
  position: string;
};

export const EmployeeProfile: React.FC<EmployeeProfileProps> = (props) => {
  const { imageUrl, name, position } = props;
  return (
    <section className={componentClasses.container}>
      <img alt={`Photo of ${name}`} className={componentClasses.image} src={imageUrl} />
      <section className={componentClasses.details}>
        <p className={componentClasses.name}>{name}</p>
        <p className={componentClasses.position}>{position}</p>
      </section>
    </section>
  );
};
